import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ITree} from '../interfaces/tree.interface';
import {environment} from '../../environments/environment';
import {IData} from '../interfaces/data.interface';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(
    private http: HttpClient
  ) { }

  getData$(): Observable<any> {
    return this.http.get<IData[]>(environment.data.data);
  }

  getTree$(): Observable<any> {
    return this.http.get<ITree>(environment.data.tree);
  }
}
