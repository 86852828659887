import { Injectable } from '@angular/core';
import {DataService} from './data.service';

@Injectable({
  providedIn: 'root'
})
export class TagsService {

  private tags: {[key: string]: any[]} = {};

  constructor(
    private dataService: DataService
  ) {
    this.getData$();
  }

  getData$() {
    this.dataService.getData$().subscribe((data) => {

      data.forEach((datum) => {
        if (datum.tags && Array.isArray(datum.tags)) {
            datum.tags.forEach((tag: string) => {
                const _tag = tag.toLowerCase();
                this.tags[_tag] = this.tags[_tag] || [];
                this.tags[_tag].push(datum);
            });
        }
      });
    });
  }

  getTag(tag) {
    return this.tags[tag.toLowerCase()];
  }
}
