import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-cookie',
  templateUrl: './cookie.component.html',
  styleUrls: ['./cookie.component.scss']
})
export class CookieComponent implements OnInit {

  private cookieName = 'ca_cookie_accept';
  private expires = 365;
  private path = '/';

  public show = false;

  @Input('data-ca-cp-policy-updated-epoch') caUpdatedEpoch: number;

  constructor() { }

  now() {
    return Math.floor((+new Date()) / 1000);
  }

  openCookie(key) {
    const prefix = `${key}=`;
    return decodeURIComponent(document.cookie).split(/;\s*/)
      .filter((pair) => pair.indexOf(prefix) === 0)
      .map((part) => part.substring(prefix.length))
      .shift();
  }

  acceptCookie() {
    this.show = false;
    const now = this.now();
    document.cookie = `${this.cookieName}=${now};max-age=${this.expires * 24 * 60 * 60};path=${this.path}`;
  }

  ngOnInit() {
     const now = this.now();
     const acceptEpoh: number = +this.openCookie(this.cookieName);

     if (Number.isNaN(acceptEpoh)) {
        this.show = true;
     } else {
       if (this.caUpdatedEpoch > now) {
         throw new Error(`You can future date the cookie policy`);
       }

       if (this.caUpdatedEpoch > acceptEpoh) {
         this.show = false;
       }
     }
  }

}
