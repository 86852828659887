import { Injectable } from '@angular/core';
import {DataService} from './data.service';
import * as elasticlunr from 'elasticlunr';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  private index;
  public data;

  constructor(
    private dataService: DataService
  ) {
    this.getData$();
  }

  getData$() {
    this.dataService.getData$().subscribe((data) => {

        const clean = data.map((document) => {
          document.value = document['@value'];
          delete document['@value'];
          return document;
        });

        this.index = elasticlunr(function() {
            Object.keys(clean[0]).forEach((field) => {
                this.addField(field);
            });

            this.setRef('id');
        });

        clean.forEach((document) => {
          this.index.addDoc(document);
        });

        this.data = clean;
    });
  }

  query(q, options = {expand: true}) {
    const results = this.index.search(q, {
      ...options,
      fields : {
          subheading2: {boost: 100},
          subheading: {boost: 1000},
          heading: {boost: 10000},
          category: {boost: 2},
          value : {boost: 0}
      }
    });
    const res = results.sort((a, b) => {
        const a$score = a.score;
        const b$score = b.score;

        if (a$score > b$score) { return 1; }
        if (b$score > a$score) { return -1; }
        return 0;

    }).map((result) => {
        result.doc = this.data.find((doc) => doc.id === +result.ref);
        return result;
    }).reverse();


    console.log("res", res);

    return res;

  }
}
