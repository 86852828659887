import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstline'
})
export class FirstlinePipe implements PipeTransform {

  transform(value: string, args?: any): any {
    if (value) {
      return value.split(/\n/g).shift();
    }
  }

}
