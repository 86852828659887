import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TreeComponent } from './components/tree/tree.component';
import {DataService} from './providers/data.service';
import {TreeModule} from 'primeng/tree';
import {HttpClientModule} from '@angular/common/http';
import { Nl2brPipe } from './pipes/nl2br.pipe';
import {SearchService} from './providers/search.service';
import {ButtonModule, InputTextModule} from 'primeng/primeng';
import {FormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { FirstlinePipe } from './pipes/firstline.pipe';
import {TagsService} from './providers/tags.service';
import { HrComponent } from './components/hr/hr.component';
import { FooterComponent } from './components/footer/footer.component';
import { CookieComponent } from './components/cookie/cookie.component';

@NgModule({
  declarations: [
    AppComponent,
    TreeComponent,
    Nl2brPipe,
    FirstlinePipe,
    HrComponent,
    FooterComponent,
    CookieComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TreeModule,
    FormsModule,
    BrowserAnimationsModule,
    ButtonModule,
    InputTextModule
  ],
  providers: [
    DataService,
    SearchService,
    TagsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
