import {Component, OnInit} from '@angular/core';
import * as UUID from 'uuid/v4';

import {DataService} from '../../providers/data.service';
import {ITree} from '../../interfaces/tree.interface';
import {SearchService} from '../../providers/search.service';
import {TreeNode} from 'primeng/api';
import {TagsService} from '../../providers/tags.service';

interface TreeBranches {
  data: TreeNode[];
}

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss']
})

export class TreeComponent implements OnInit {

  public tree: TreeBranches;
  public ieBrowser = false;

  public result: any;
  public query: string;
  public selected: any;
  public selectedData: any;
  public searchResults: any;
  public tagResult: any;
  public selectedTag: string;
  public selectedQuery: string;

  public mode: 'search' | 'browse' | 'home' | 'tags' = 'home';

  private SEPERATOR = ' » ';

  constructor(
    private dataService: DataService,
    private searchService: SearchService,
    private tagsService: TagsService
  ) {}

  public makeSelected(event) {
    this.selectNode(event.node);
  }

  public searchSelect(result, event) {
    event.preventDefault();
    this.selectNode(this.findInTree(this.tree.data, result.id, true));
  }

  public clickTag(tag, event) {
      event.preventDefault();
      this.mode = 'tags';
      this.selectedTag = tag;
      this.tagResult = this.tagsService.getTag(tag).map((result) => {
        return {
          id: result.id,
          title: [
            result.category,
            result.heading,
            result.subheading,
            result.subheading2
          ].filter(Boolean).join(this.SEPERATOR),
          body: result['@value']
        };
      });
  }

  public onEnter(event) {
      if (event.key.toUpperCase() === 'ENTER' && this.query) {
          this.fullSearch();
      }
  }

  public fullSearch() {
    if (this.query) {
      this.selectedQuery = this.query;
      const r = this.searchService.query(this.query);
      this.searchResults = r.map((result) => {
          return {
            id: result.doc.id,
            title: [
              result.doc.category,
              result.doc.heading,
              result.doc.subheading,
              result.doc.subheading2
            ].filter(Boolean).join(this.SEPERATOR),
            body: result.doc.value
          };
      });
      this.mode = 'search';
    }
  }

  private findInTree(tree, ref, asSlug = false, gotIt = null) {
      tree.forEach((node) => {
        const matching = asSlug ? 'slug' : 'id';
        if ('data' in node && matching in node.data && node.data[matching] === ref) {
          gotIt = node;
        } else if ('children' in node) {
          gotIt = this.findInTree(node.children, ref, asSlug, gotIt);
        }
      });
      return gotIt;
  }

  private expandUpFrom(node) {
    node.expanded = true;
    if (node.data.parent !== null) {
      this.expandUpFrom(this.findInTree(this.tree.data, node.data.parent));
    }
  }

  private selectNode(node) {
    this.mode = 'browse';
    this.selected = node;
    if (node.data !== null) {
      this.selectedData = node.data;
    }
    this.expandUpFrom(node);
  }

  private getTree(): void {
    this.dataService.getTree$().subscribe((tree: ITree) => {
      this.tree = {
        data: this.process_tree(tree)
      };
    });
  }

  private process_tree(tree: ITree , parent = null): TreeNode[] {
    const data: TreeNode[] = [];
    const atValue = '@value';

    Object.entries(tree).forEach((node: any) => {
      const [key, value] = node;
      if (key !== atValue) {

        const content = atValue in value && 'value' in value[atValue] ? value[atValue].value : null;
        const title = atValue in value && 'title' in value[atValue] ? value[atValue].title : null;
        const tags = atValue in value && 'tags' in value[atValue] ? value[atValue].tags : null;
        const _key = UUID();

        const payload: TreeNode = {
          label: key,
          data: {
            content,
            title,
            tags,
            parent,
            id: _key,
            slug: atValue in value && 'id' in value[atValue] ? value[atValue].id : null
          },
          key: _key
        };

        const valueLength = Object.keys(value).length;

        if (valueLength > 1 || (valueLength === 1 && !(atValue in value))) {
          payload.children = this.process_tree(value, _key);
        }

        data.push(payload);
      }
    });

    return data;
  }

  detectIE() {
      const ua = window.navigator.userAgent;

      const msie = ua.indexOf('MSIE ');
      if (msie > 0) {
          // IE 10 or older => return version number
          return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
      }

      const trident = ua.indexOf('Trident/');
      if (trident > 0) {
          // IE 11 => return version number
          const rv = ua.indexOf('rv:');
          return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
      }

      // other browser
      return false;
  }

  ngOnInit() {
    if (this.detectIE()) {
      this.ieBrowser = true;
    } else {
        this.getTree();
    }
  }

}
